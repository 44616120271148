import { Injectable } from '@angular/core';
import {
  MessagePayload,
  Messaging,
  getMessaging,
  getToken,
  onMessage,
} from 'firebase/messaging';
import { environment } from '../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { relative } from 'path';
import { relativeTimeRounding } from 'moment';


@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  private messaging: Messaging;

  constructor(
    private router: Router,
    private readonly route: ActivatedRoute,
  ) {
        // this.messaging = getMessaging();
        // this.requestPermission();
        // this.listen();
      if ('Notification' in window) {
        this.messaging = getMessaging();
        this.requestPermission();
        this.listen();
      } else {
        alert("Browser does not support Notifications API.")
      }
  }

  private handleMessage(payload: MessagePayload): void {
    console.log('handleMessage called with payload:', payload);
    
  
    
    if (localStorage.getItem("sessionToken")) {

      const isRelevantRoute = this.router.url.includes('chat') || this.router.url.includes('srm');

      const isNewMessageNotification = payload.notification?.title.includes('You have a new message.');
      
      if (isRelevantRoute && isNewMessageNotification) {
        this.refreshCurrentRoute();
      }

    } else {
      console.log('User is not logged in, checking notification permission');
      if (Notification.permission === 'granted') {
        console.log('Notification permission granted, creating notification');
        this.createNotification(payload);
      } else {
        console.log('Notification permission not granted');
      }
    }
  }



  private refreshCurrentRoute(): void {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]).then(() => {
      }).catch(error => {
        console.error('Error navigating back to original URL:', error);
      });
    }).catch(error => {
      console.error('Error navigating to root:', error);
    });
  }

  private createNotification(payload: MessagePayload): void {
    console.log('Creating notification with payload:', payload);
    if (!payload.notification) {
      return;
    }

    const notification = new Notification(
      payload.notification.title || 'Notification Title',
      {
        body: payload.notification.body,
        icon: payload.notification.icon,
      }
    );

    notification.onclick = () => {
      if( payload.notification?.title.includes('You have a new message.')) {
        this.router.navigate(["/chat"], {relativeTo: this.route})
      } else if(payload.fcmOptions?.link) {
        window.open(payload.fcmOptions.link);
      }
    };
  }

  private requestPermission(): void {
    console.log("Requesting permission for notifications...");
    Notification.requestPermission().then(permission => {
      console.log('Notification permission result:', permission);
      if (permission === 'granted') {
        this.getToken();
      }
    }).catch(err => {
      console.error('Error requesting notification permission:', err);
    });
  }

  private getToken(): void {
    console.log('Attempting to get FCM token');
    getToken(this.messaging, { vapidKey: environment.vapidKey })
      .then(currentToken => {
        if (currentToken) {
          console.log('FCM token received:', currentToken);
          if (!localStorage.getItem('deviceToken')) {
            localStorage.setItem('deviceToken', currentToken);
            this.sendTokenToServer(currentToken);
            console.log('Token saved to local storage');
          } else {
            console.log('Token already saved in local storage');
          }
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      })
      .catch(err => {
        console.error('An error occurred while retrieving token:', err);
      });
  }

  private sendTokenToServer(token: string): void {
    console.log("Sending token to server:", token);
    // Implement actual send logic here
  }

  private listen(): void {
    console.log('Setting up onMessage listener');
    onMessage(this.messaging, (payload) => {
      console.log('Message received in onMessage listener:', payload);
      this.handleMessage(payload);
    });
  }
}
